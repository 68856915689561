import { type FC, useEffect, useState } from 'react';
import { Button, Card, Table } from 'flowbite-react';
import { type Analytic } from 'types/analytic';
import { type Pagination } from 'types/pagination';
import { type AnalyticsResponse, getAnalytics } from 'domains/api/getAnalytics';
import AnalyticForm from 'components/molecules/Analytics/AnalyticForm';
import RegistrationProcess from 'components/molecules/Analytics/RegistrationProcess';
import AnalyticTableRow from './AnalyticTableRow';

const AnalyticsTable: FC<{ analyticsData: AnalyticsResponse }> = ({
  analyticsData,
}) => {
  const [analyticsResponse, setAnalyticsResponse] = useState<Analytic[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const fetchAnalytics = async (page: number) => {
    const data: AnalyticsResponse = await getAnalytics(page);
    setAnalyticsResponse([...analyticsResponse, ...data.analytics]);
    setPagination(data.pagination);
  };

  useEffect(() => {
    setAnalyticsResponse([...analyticsResponse, ...analyticsData.analytics]);
    setPagination(analyticsData.pagination);
  }, []);
  const onLoadMore = async () => {
    if (pagination === null) return;
    await fetchAnalytics((pagination.currentPage += 1));
  };

  return (
    <>
      {analyticsResponse.length > 0 ? (
        <Table hoverable>
          <Table.Head>
            <Table.HeadCell>ID</Table.HeadCell>
            <Table.HeadCell>サイト名</Table.HeadCell>
            <Table.HeadCell>URL</Table.HeadCell>
            <Table.HeadCell>ステータス</Table.HeadCell>
            <Table.HeadCell>権限</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {analyticsResponse.map((analytic) => {
              return <AnalyticTableRow key={analytic.id} analytic={analytic} />;
            })}
          </Table.Body>
        </Table>
      ) : (
        <Card>
          <RegistrationProcess />
          <AnalyticForm />
        </Card>
      )}
      {analyticsResponse.length > 0 && pagination && !pagination.isLastPage && (
        <div className={'flex justify-center'}>
          <Button onClick={onLoadMore} size="xl" color="light" className="mt-4">
            <span className={'text-md'}>次へ</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default AnalyticsTable;
